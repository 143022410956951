// 
// breadcrumb.scss
//

.breadcrumb {
    font-size: .65rem;
}

// Breadcrumb item arrow
.breadcrumb-item {
    >a {
        color: $gray-700;
    }

    +.breadcrumb-item {
        &::before {
            // content:">";
            font-family: "Material Design Icons";
        }
    }
}