@import "./icons.scss";
// @import "../../../node_modules/ag-grid-community/src/styles/ag-grid.scss";
// @import "../../../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine.scss";
@import "ag-grid-community/dist/styles/ag-grid.scss";
//@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1230px;
    padding-top: 11px;
  }
}

.modal-dialog-scrollable .modal-body {
  overflow-y: hidden;
}

#download-uilibrary {
  &:hover {
    color: #fe5000;
  }
}
.apexcharts-datalabel-value {
  transform: translate(0, -25%);
}

.leaflet-pane {
  z-index: 1;
}

svg > rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.offcanvas-body {
  .ps__rail-y {
    max-height: 0;
  }
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
}

.react-grid-Main {
  height: 400px;
}

.react-grid-Grid {
  height: 400px;
}

.react-grid-Canvas {
  height: 363px;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

// .accordion-header {
//   color: #495057 !important;
//   background-color: transparent !important;

//   .active {
//     color: #4d63cf !important;
//     background-color: #eef1fd !important;
//   }
// }

.design-studio-button {
  background-color: #fe5000;
  color: white;
}

#create-dashboard {
  margin-left: 18px;
}

.design-studio-button:hover {
  background-color: #fe5000;
  color: white;
}

.design-link-style {
  color: #ef7e1a;
}

.design-link-style:hover {
  color: #ef7e1a;
}

.solution-card-style {
  color: black;
}

.solution-card-style:hover {
  color: black;
}

Link:hover {
  pointer-events: none;
}

//tabs

.container1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  word-break: break-all;
  border-top-left-radius: 50px;
  margin-top: -42px;
}

.bloc-tab-border {
  width: fit-content;
  background: #ffffff;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  // border-color: "4px solid red" !important;
  padding: 0 9px;
}

.bloc-tabs {
  display: flex;
  width: 100%;
}

.tabs {
  padding: 10px;
  text-align: center;
  width: auto;
  text-align: center;
  background: #ffffff;
  cursor: pointer;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
  font-size: 13px;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  border-bottom: 5px solid #ef7e1a;
  color: #ffffff;
  // width: fit-content;
}

// .active-tabs::before {
//   content: "";
//   display: block;
//   position: absolute;
//   top: -5px;
//   left: 50%;
//   transform: translateX(-50%);
//   width: calc(100% + 2px);
//   height: 5px;

// }

button {
  border: none;
}

.content-tabs {
  flex-grow: 1;
}

.content {
  width: 100%;
  height: 100%;
  display: none;
}

.content p {
  width: 100%;
  height: 100%;
  padding: 0;
}

.active-content {
  display: block;
  padding: 0;
}

.formhead {
  font-size: 1.2rem;
  font-weight: 500;
}

.formImg img {
  padding-top: 10px;
  width: 470px;
  margin-top: 80px;
}

.dropd {
  margin-left: -40px;
}

.creNew img {
  width: 360px;
  height: 204px;
}

.subbutton {
  background-color: #fe5000;
  color: #fff;
  width: 200px;
}

.subbutton:hover {
  background-color: white;
  color: #fe5000 !important;
  border-color: #fe5000;
}

.secondarybutton {
  background-color: white;
  color: gray;
  border-color: gray;
  width: max-content;
}

.assetsbutton {
  background-color: white;
  color: gray;
  border-color: gray;
  width: 25%;
}

.secondarybutton:hover {
  background-color: white;
  color: #fe5000 !important;
  border-color: #fe5000;
}

.resetbutton {
  background-color: white;
  color: gray;
  border-color: gray;
  width: 10%;
}

.provisionbutton:hover {
  background-color: white;
  color: #fe5000 !important;
  border-color: #fe5000;
}

.loginbutton {
  background-color: #fe5000;
  color: #fff;
}

.loginbutton:hover {
  background-color: #6dcdb8;
  color: #fe5000 !important;
  border-color: #fe5000;
}

.plusimg {
  width: 365px;
}

.plusimg img {
  width: 360px;
  height: 206px;
}

.Rcolor {
  color: red;
}

.Gcolor {
  color: green;
}

.avataricon {
  width: 50px !important;
}

.btn-primary {
  background-color: #fe5000 !important;
  border-color: #fe5000 !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.bubble-background {
  width: 750px;
  height: 600px;
  margin-left: 170px;
  margin-top: 10px;
}

//style={{width:'60%', height:'60%', marginLeft:'170px', marginTop:'50px'}}
.animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
  background-size: 4px;
}

.topnotiIcon {
  padding: 0;
  margin-left: 0.8rem;
}

// .round {position:absolute;width:100px;height:100px;border-radius:500px;background-image: linear-gradient(to bottom right, rgb(9, 237, 195), rgb(9, 150, 237));}
// .round:hover{
//   background-image: linear-gradient(to right, rgb(52, 220, 235), rgb(58, 52, 235))
// }
.round1 {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 1000px;
  top: 70%;
  left: 64%;
  background-image: linear-gradient(to bottom right, rgb(89, 52, 235));
}

.round1:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

//
.round2 {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 1000px;
  background-image: linear-gradient(to bottom right, #fe0944, #feae96);
  top: 14%;
  left: 49%;
  cursor: pointer;
}

.round2:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.round3 {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 1000px;
  top: 56%;
  left: 17%;
  background-image: linear-gradient(
    to bottom right,
    rgb(207, 9, 237),
    rgb(237, 9, 131)
  );
}

.cardBackground {
  color: black !important;
}

.cardBackground :hover {
  color: black !important;
  background-color: #ffede5 !important;
  border: 5px;
  border-color: #fe5000;
}

.round3:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.roundlogo {
  position: absolute;
  width: 130px;
  height: 130px;
  border-radius: 1200px;
  top: 43%;
  left: 42%;

  background-image: linear-gradient(
    to bottom right,
    rgb(52, 220, 235),
    rgb(58, 52, 235)
  );
}

.roundlogo:hover {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 53, 9, 255),
    rgba(255, 207, 0, 255)
  );
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.accordian-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #fe5000;
  z-index: 1;
  margin-left: -10px;
  margin-top: 20px;
}

.mapped-block {
  color: black;
  display: block;
  margin-top: 10%;
  border: 1px solid #dfdfe0;
  padding: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.mapped-block:hover {
  box-shadow: #fe5000 0px 1px 0px 0px, #fe5000 0px 0px 1px 0px,
    #fe5000 0px 0px 0px 1px, #fe5000 1px 0px 0px 0px;
  color: #fe5000;
}

/* .btn btn-lg btn-primary{
  background: #ff8200 !important;
  color: white !important;
}
 */
/* .sb-avatar__text{
  margin-top: 15px !important;
  width: 72px !important;
  height: 70px !important;
} */
// .business-anchor-style::after{
// position:absolute;
// left: 0;
// bottom: 0;
// width: 100px;
// height: 2px;
// background: currentColor;
// transform: scaleX(0);
// transform-origin: left;
// transition: transform 250ms ease-in;
// }

// .business-anchor-style:hover::after{
//   transform: scaleX(1);
// }

.accordion-button {
  background-color: white;
  color: black !important;
}

.accordion-bgcolor {
  background-color: rgb(255, 237, 229) !important;
}

.accordion-button i.moat-info {
  display: none;
}

.accordion-item :hover {
  i.moat-info {
    padding-top: 2px;
    color: black;
    display: none;
  }
}

.accordion-button:hover {
  i.moat-info {
    display: inherit;
  }
}

.scrollbar {
  overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-left: 2px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

/* custom colors  */
.scrollbar-primary::-webkit-scrollbar {
  background-color: #f5f5f5;
}

.scrollbar-primary {
  scrollbar-color: #4285f4 #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285f4;
}

.modal-body::-webkit-scrollbar,
.ag-theme-alpine .ag-layout-normal::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

.modal-body::-webkit-scrollbar-track,
.ag-theme-alpine .ag-layout-normal::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.modal-body::-webkit-scrollbar-thumb,
.ag-theme-alpine .ag-layout-normal::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  background-color: #c1c1c1;
  width: 8px;
}

.buttontext {
  font-size: 9px;
  display: inline-block;
  padding: 0px;
  margin: 0 0 1px 0;
  color: #fe5000;
}

.linktext {
  font-size: 10px;
  padding: 0px;
  margin: 0px;
  color: #fe5000;
}

.abutton {
  height: 24px;
  // width:fit-content;
}

.abutton i {
  padding-right: 3px;
}

.right-vertical-menu {
  .metismenu {
    li {
      display: block;
      width: 100%;
      position: relative !important;
      height: 70px;

      a {
        position: absolute !important;
        right: 0;
      }
    }
  }
}

//

.moattags {
  height: fit-content;
  width: fit-content;
  background-color: #c5ebe3 !important;
  border-radius: 5px;
  font-size: 60%;
  padding: 3%;
}

.moattags :hover {
  background-color: #c5ebe3 !important;
  border-radius: 5px;
}

.moatverifytags {
  height: fit-content;
  width: fit-content;
  font-size: 60%;
  padding: 3%;
  background-color: #eaf6ed !important;
  border-radius: 5px;
}

.moatverifytags:hover {
  background-color: #eaf6ed !important;
  border-radius: 5px;
}

.cart-btn {
  margin-top: 85px;
  margin-left: 0px;
  background: transparent;
  // background: rgb(252, 80, 3);
  border-radius: 50%;
  width: 20px;
  height: 32px;
  position: absolute;
  right: 50px;
  z-index: 2;
  top: 20px;
  box-shadow: none !important;
  color: #555b6d;
  border: 0;
}

.cart-btn i {
  color: rgb(255, 255, 255);
  font-size: 12px;
  position: absolute;
  left: 9px;
  top: 10px;
}

.cart-sidebar {
  height: 100%;
  width: 5%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.cart-sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.cart-sidebar #side-menu span {
  display: none;
}

.cart-sidebar #sidebar-menu ul li a:hover {
  color: #111 !important;
}

.vertical-cart-collpsed .cart-sidebar #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}

.vertical-cart-collpsed.cart-sidebar #side-menu span {
  text-align: right;
  display: contents;
}

.cart-sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.fontPoppins {
  font-family: "Poppins";
}

.openbtn:hover {
  background-color: #444;
}

#layout_main {
  transition: margin-left 0.5s;
  padding: 16px;
  width: 95%;
}

.cart-vertical-collpsed .cart-sidebar {
  width: 80px;
  transition: margin-left 0.5s;
}

.optionDropDownIcon {
  appearance: auto !important;
}

.cardAvatarBackground {
  background-color: #ffede5;
  width: fit-content;
  height: auto;
  border-radius: 10px;
  padding: 10%;
}

// scrollbar container
.scrollbar-container {
  z-index: 0 !important;
}

.ps__rail-y {
  width: 10px !important;
}

.ps__thumb-y {
  width: 6px !important;
}

.ps__rail-x {
  width: 0px !important;
}

.ps__thumb-x {
  width: 0px !important;
}

.theme-table tbody tr {
  cursor: pointer;
}

.container {
  padding: 20 0 0 0;
}

.kanvas_accordian_wrap {
  .accordian_body {
    color: #333;
  }

  .accordion-button::after {
    content: "";
    background: none;
  }

  .accordion-button {
    padding: 0;
    background: transparent;
    box-shadow: none;
    text-align: center;
  }

  .accordion-body {
    padding: 0;
    margin: 1px 0;
  }

  .accordion-button {
    padding: 10px 0;
    border-radius: 0;
    display: block;
  }

  .accordion-button[aria-expanded="true"] {
    padding: 10px 0;
    border-radius: 0;
    display: block;

    z-index: 0;
    background: linear-gradient(90deg, #707070 50%, transparent 50%),
      linear-gradient(90deg, #707070 50%, transparent 50%),
      linear-gradient(0deg, #707070 50%, transparent 50%),
      linear-gradient(0deg, #707070 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 5px 2px, 5px 2px, 2px 5px, 2px 5px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    border-radius: 2px;
  }

  .accordion-item {
    border: none;
  }

  .form-check {
    padding: 5px 15px;
  }
}

.kanvas_main_header {
  .form-switch .form-check-input {
    width: 3em;
    height: 1.5em;
    margin-left: -3em;
  }

  .form-switch .form-check-input:focus,
  .form-switch .form-check-input:active {
    box-shadow: none;
    border: 1px solid #fe5000;
  }
}

.admin_infra_wrapper {
  .form-switch .form-check-input {
    width: 3em;
    height: 1.5em;
    margin-left: 3em;
  }

  .form-switch .form-check-input:focus,
  .form-switch .form-check-input:active {
    box-shadow: none;
    border: 1px solid #fe5000;
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .cart-sidebar {
    padding-top: 15px;
  }

  .cart-sidebar a {
    font-size: 18px;
  }
}

.form-check-input {
  border-color: #fe5000c7;
}

.form-check-input:checked {
  background-color: #fe5000c7;
  border-color: #fe5000c7;
}

body.sidebar-enable {
  .vertical-menu {
    display: block;
  }

  .breadcrumbLabel {
    left: 22% !important;
  }
}

#side-menu {
  .sideBarAfterHover {
    display: none;
  }

  li:hover {
    .sideBarAfterHover {
      display: inline;
    }

    .sideBarBeforeHover {
      display: none;
    }
  }
}

/* Tabs */
.nav-tabs {
  .nav-link {
    cursor: pointer;
    color: #74788d;
  }

  .nav-link.active {
    color: #fe5000;
    background-color: #fff;
    border-color: #ced4da #ced4da #fff;
  }
}

.vertical-collpsed {
  .breadcrumb {
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 100px;
    top: 15px;
  }
}

.sidebar-enable {
  .breadcrumb {
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 280px;
    top: 15px;
  }
}

.vertical-collpsed {
  div#sidebar-menu {
    height: 500px;
    overflow-y: scroll;
    width: fit-content;
    background: transparent;
  }

  div#sidebar-menu::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    margin-left: 2px;
  }

  div#sidebar-menu::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.2);
  }
}

.selected-assets-item {
  height: 450px;
}

.ql-editor {
  min-height: 250px;
  color: #495057;
}

// .quill {
//   position: relative;
// }
// .ql-container.ql-snow {
//   height: 130px;
// }
// .ql-editor::-webkit-scrollbar {
//   width: 6px;
//   height: 6px;
//   margin-left: 2px;
// }

// .ql-editor::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//   background: rgba(0, 0, 0, 0.2);
// }

// .ql-toolbar.ql-snow {
//   position: absolute;
//   bottom: -40px;
//   width: 100%;
// }
.atom-text-wrap {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.editor-error-wrapper {
  label {
    color: #f46a6a;
  }

  .error {
    .ql-toolbar.ql-snow {
      border-color: #f46a6a;
    }

    .ql-container.ql-snow {
      border-color: #f46a6a;
    }
  }
}

.offcs-50.offcanvas.offcanvas-end.show {
  width: 50%;
}

.new-btn-card {
  border: 1px solid #fe5000;
  color: #fe5000;
}

.new-btn-card:hover,
.new-btn-card:active,
.new-btn-card:focus {
  border: 1px solid #fe5000;
  color: #fe5000;
  background: transparent;
}

.notification-badge {
  position: absolute;
  left: 13px;
  height: 20px;
  width: 20px;
  top: 16px;
  background-color: #fe5000 !important;
  padding-top: 5px;
}

#provision-button {
  background-color: #fe5000 !important;
  border: 1px solid #fe5000;
  color: #fff;
  padding: 8px 35px !important;
}

#provision-button:hover,
#provision-button:active,
#provision-button:focus {
  background-color: #fe5000;
  border: 1px solid #fe5000;
  color: #fff !important;
}
.white-tooltip {
  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    border-color: rgba(211, 211, 211, 0);
    border-right-color: #fff;
  }
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-color: rgba(211, 211, 211, 0);
    border-top-color: #fff;
  }
}
.custom-card:hover {
  background-color: #ffebe3;
  border: 2px solid #fe5000;
}

.ag-header-cell-text {
  text-align: center !important;
}