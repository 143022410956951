label[for='assetName']::after
{
   content:'*';
   color:red;
   margin:2px;
}

label[for='assetType']::after
{
   content:'*';
   color:red;
   margin:2px;
}

.sort-wrapper, .sortable span{
   position: absolute;
   right: 15px;
}
.sort-wrapper .label-icon{
   font-size: 18px;
}